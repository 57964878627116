<template>
  <div class="d-inline">
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->
    <v-dialog v-model="dialog" class="dialogdetail" :fullscreen="isMobile" max-width="600">
      <v-card :loading="loading" color="grey lighten-5">
        <v-card-title class="text-h5 grey lighten-2">
          <span>Modify Template</span>
          <v-spacer></v-spacer>

          <v-btn color="light" @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-4 vcard-text-dd-hh" style="">
          <v-form ref="form" v-model="valid" lazy-validation :disabled="loading">
            <v-row>
              <v-col>
                <v-autocomplete clearable v-model="formData.role" outlined dense class="mb-2" placeholder="Role" label="Role" :items="master.role"></v-autocomplete>
                <v-autocomplete clearable v-model="formData.source_document" :rules="formRules.source_document" outlined dense class="mb-2" placeholder="Source Document" label="Source Document" :items="master.source_document"></v-autocomplete>
                <v-text-field v-model="formData.document_type" :rules="formRules.document_type" outlined dense class="mb-2" placeholder="Document Type" label="Document Type"></v-text-field>
                <v-autocomplete clearable v-model="formData.area_code" outlined dense class="mb-2" placeholder="Area Code" label="Area Code" :items="master.area" item-text="area_code" item-value="area_code"></v-autocomplete>
                <v-autocomplete clearable v-model="formData.department_id" outlined dense class="mb-2" placeholder="Department Code" label="Department Code" :items="master.department" item-text="dept_code" item-value="id"></v-autocomplete>
                <v-text-field v-model="formData.description" outlined dense class="mb-2" placeholder="Description" label="Description"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" @click="saveData()" :loading="loading_save">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="light" @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    dataId: {
      type: Number,
      default: 0,
    },
  },
  components: {
  },

  data() {
    return {
      loading: false,
      loading_save: false,
      loading_master: false,
      dialog: false,
      master: {
        role: [],
        source_document: [],
        area: [],
        department: [],
      },
      formData: {
        id: '',
        role: '',
        source_document: '',
        document_type: '',
        area_code: '',
        department_id: '',
        description: '',
      },
      formRules: {
        source_document: [
          v => !!v || 'Source Document is required',
        ],
        document_type: [
          v => !!v || 'Document Type is required',
        ],
      },
      valid: true,
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
  },
  computed: {

  },
  methods: {
    /**
     * showDialog
     * Method untuk menampilkan dialog
    */
    showDialog() {
      this.dialog = true;
      this.formData.role = '';
      this.formData.source_document = '';
      this.formData.document_type = '';
      this.formData.area_code = '';
      this.formData.department_id = '';
      this.formData.description = '';


      this.getDetail();
      this.getMaster();
    },

    /**
     * closeDialog
     * Method untuk menutup dialog
    */
    closeDialog() {
      this.dialog = false;
      this.formData.role = '';
      this.formData.source_document = '';
      this.formData.document_type = '';
      this.formData.area_code = '';
      this.formData.department_id = '';
      this.formData.description = '';

        if (typeof this.callback == 'function')
          this.callback();
    },


    /**
     * vaidate()
     * Method untuk melakukan validasi form
    */
    validate() {
      this.$refs.form.validate()
    },

    reset() {
      this.$refs.form.reset()
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async getMaster() {
      this.loading_master = true;

      this.master.role = [];
      this.master.source_document = [];
      this.master.area = [];
      this.master.department = [];

      const uri = 'approvaltemplate/master';
      const config = {
        cacheConfig: false,
      };
      await this.$axios.get(uri, config)
        .then((res) => {
          const resData = res.data;
          if (resData.status == 'success') {

            this.master.role = resData.data.role;
            this.master.area = resData.data.area;
            this.master.source_document = resData.data.source_document;
            this.master.department = resData.data.department;

          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.loading_master = false;
        })
        .catch((e) => {
          this.loading_master = false;
          this.axiosErrorHandler(e);
        });
    },

    async getDetail() {
      this.loading = true;

      this.formData.id = null;
      this.formData.role = null;
      this.formData.source_document = null;
      this.formData.document_type = null;
      this.formData.area_code = null;
      this.formData.department_id = null;
      this.formData.description = null;

      const uri = 'approvaltemplate/detail';
      const config = {
        cacheConfig: false,
        params: {
          id: this.dataId
        }
      };
      await this.$axios.get(uri, config)
        .then((res) => {
          const resData = res.data;
          if (resData.status == 'success') {

            this.formData.id = resData.data.approval_template.id;
            this.formData.role = resData.data.approval_template.role;
            this.formData.source_document = resData.data.approval_template.source_document;
            this.formData.document_type = resData.data.approval_template.document_type;
            this.formData.area_code = resData.data.approval_template.area_code;
            this.formData.department_id = resData.data.approval_template.department_id;
            this.formData.description = resData.data.approval_template.description;

          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.axiosErrorHandler(e);
        });
    },

    async saveData() {
      this.validate();

      if (!this.valid) return;

      this.loading_save = true;

      const uri = 'approvaltemplate/update';
      const formData = new FormData();
      formData.append("id", this.formData.id);
      if(this.formData.role) formData.append("role", this.formData.role);
      if(this.formData.source_document) formData.append("source_document", this.formData.source_document);
      if(this.formData.document_type) formData.append("document_type", this.formData.document_type);
      if(this.formData.area_code) formData.append("area_code", this.formData.area_code);
      if(this.formData.department_id) formData.append("department_id", this.formData.department_id);
      if(this.formData.description) formData.append("description", this.formData.description);
      await this.$axios.post(uri, formData)
        .then((res) => {
          const resData = res.data;
          console.log(resData.status);
          if (resData.status == 'success') {
            console.log(resData);
            this.closeDialog();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.loading_save = false;
        })
        .catch((e) => {
          this.loading_save = false;
          this.axiosErrorHandler(e);
        });
    }
  },
  mounted() {
  }
};
</script>


<style scoped>
.vcard-text-dd-hh {
  max-height: 550px;
  overflow-y: auto;

}
</style>