var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","elevation":"0"}},[_c('app-data-table',{ref:"refAppDataTable",attrs:{"title":_vm.title,"uri":_vm.datatable.uri,"headers":_vm.datatable.headers,"filter-data":_vm.filterData,"show-filter":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('Edit',{attrs:{"dataId":parseInt(item.id)},on:{"callback":_vm.refreshData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","text":"","x-small":""},on:{"click":on}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('EditUser',{attrs:{"dataId":parseInt(item.id)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","text":"","x-small":""},on:{"click":on}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)})]}},{key:"filter-body",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","placeholder":"Source Document","label":"Source Document","readonly":""},model:{value:(_vm.filterData.source_document),callback:function ($$v) {_vm.$set(_vm.filterData, "source_document", $$v)},expression:"filterData.source_document"}}),_c('v-autocomplete',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","placeholder":"Role","label":"Role","items":_vm.master.role},model:{value:(_vm.filterData.role),callback:function ($$v) {_vm.$set(_vm.filterData, "role", $$v)},expression:"filterData.role"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","placeholder":"Document Type","label":"Document Type"},model:{value:(_vm.filterData.document_type),callback:function ($$v) {_vm.$set(_vm.filterData, "document_type", $$v)},expression:"filterData.document_type"}}),_c('v-autocomplete',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","placeholder":"Area Code","label":"Area Code","items":_vm.master.area,"clearable":"","item-text":"area_code","item-value":"area_code"},model:{value:(_vm.filterData.area_code),callback:function ($$v) {_vm.$set(_vm.filterData, "area_code", $$v)},expression:"filterData.area_code"}}),_c('v-autocomplete',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","placeholder":"Department Code","label":"Department Code","items":_vm.master.department,"clearable":"","item-text":"dept_code","item-value":"dept_code"},model:{value:(_vm.filterData.dept_code),callback:function ($$v) {_vm.$set(_vm.filterData, "dept_code", $$v)},expression:"filterData.dept_code"}})]},proxy:true},(_vm.isMobile)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"4","outlined":""}},[_c('v-card-title',{staticClass:"text-h5 mb-1 pb-1"},[_vm._v(_vm._s(item.source_document))]),_c('v-card-subtitle',{staticClass:"my-0 py-0 mb-3"},[_vm._v(" "+_vm._s(item.document_type)+" "),_c('br'),_vm._v(" "+_vm._s(item.area_code)+" "),_c('br'),_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('Edit',{attrs:{"dataId":parseInt(item.id)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":on}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('EditUser',{attrs:{"dataId":parseInt(item.id)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":on}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)})],1)],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }