<template>
  <div>
    <v-card flat elevation="0">
      <app-data-table ref="refAppDataTable" :title="title" :uri="datatable.uri" :headers="datatable.headers" :filter-data="filterData" show-filter>
        <template v-slot:id="{ item }">
          <Edit :dataId="parseInt(item.id)" @callback="refreshData">
            <template v-slot:activator="{ on }">
              <v-btn icon text x-small v-on:click="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </Edit>
          <EditUser :dataId="parseInt(item.id)">
            <template v-slot:activator="{ on }">
              <v-btn icon text x-small v-on:click="on">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
          </EditUser>
        </template>
        <template #filter-body>
          <v-text-field v-model="filterData.source_document" outlined dense class="mb-2" placeholder="Source Document" label="Source Document" readonly></v-text-field>
          <v-autocomplete v-model="filterData.role" outlined dense class="mb-2" placeholder="Role" label="Role" :items="master.role"></v-autocomplete>
          <v-text-field v-model="filterData.document_type" outlined dense class="mb-2" placeholder="Document Type" label="Document Type"></v-text-field>
          <v-autocomplete v-model="filterData.area_code" outlined dense class="mb-2" placeholder="Area Code" label="Area Code" :items="master.area" clearable item-text="area_code" item-value="area_code"></v-autocomplete>
          <v-autocomplete v-model="filterData.dept_code" outlined dense class="mb-2" placeholder="Department Code" label="Department Code" :items="master.department" clearable item-text="dept_code" item-value="dept_code"></v-autocomplete>
        </template>

        <template v-slot:item="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{ item.source_document }}</v-card-title>
            <v-card-subtitle class="my-0 py-0 mb-3">
              {{ item.document_type }} <br>
              {{ item.area_code }} <br>
              {{ item.description }}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Edit :dataId="parseInt(item.id)">
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on:click="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </Edit>
              <EditUser :dataId="parseInt(item.id)">
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on:click="on">
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
              </EditUser>
            </v-card-actions>
          </v-card>
        </template>
      </app-data-table>
    </v-card>


    <!-- <v-dialog v-model="dialogFilter" max-width="400px">
      <v-card>
        <v-card-title>Filter</v-card-title>
        <v-card-text>
          <v-text-field v-model="source_document" outlined dense class="mb-2" placeholder="Source Document" label="Source Document" readonly></v-text-field>
          <v-autocomplete v-model="filterData.role" outlined dense class="mb-2" placeholder="Role" label="Role" :items="master.role"></v-autocomplete>
          <v-text-field v-model="filterData.document_type" outlined dense class="mb-2" placeholder="Document Type" label="Document Type"></v-text-field>
          <v-autocomplete v-model="filterData.area_code" outlined dense class="mb-2" placeholder="Area Code" label="Area Code" :items="master.area" item-text="area_code" item-value="area_code"></v-autocomplete>
          <v-autocomplete v-model="filterData.dept_code" outlined dense class="mb-2" placeholder="Department Code" label="Department Code" :items="master.department" item-text="dept_code" item-value="dept_code"></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="getData(true), dialogFilter = false">Filter</v-btn>
          <v-btn @click="resetFilter(), dialogFilter = false">Clear</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialogFilter = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import Edit from './Edit.vue';
import EditUser from './EditUser.vue';
export default {
  props: {
    title: String,
    source_document: String,
  },
  components: {
    Edit,
    EditUser,
  },
  data() {
    return {
      loading: null,
      datatable: {
        uri: "approvaltemplate/data",
        headers: [
          { text: "#", value: "id" },
          { text: "Role", value: "role" },
          { text: "Source Document", value: "source_document" },
          { text: "Document Type", value: "document_type" },
          { text: "Area Code", value: "area_code" },
          { text: "Dept Code", value: "dept_code" },
          { text: "Description", value: "description" },
        ],
      },
      filterData: {
        role: '',
        source_document: this.source_document,
        document_type: '',
        area_code: '',
        description: '',
        dept_code: ''
      },
      master: {
        role: [],
        source_document: [],
        area: [],
        department: [],
      },
      loading_master: false,
      dialogFilter: false,
    };
  },

  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    async getMaster() {
      this.loading_master = true;

      this.master.role = [];
      this.master.source_document = [];
      this.master.area = [];
      this.master.department = [];

      const uri = 'approvaltemplate/master';
      const config = {
        cacheConfig: false,
      };
      await this.$axios.get(uri, config)
        .then((res) => {
          const resData = res.data;
          if (resData.status == 'success') {

            this.master.role = resData.data.role;
            this.master.area = resData.data.area;
            this.master.source_document = resData.data.source_document;
            this.master.department = resData.data.department;

          } else {
            this.setAlert(resData.status, resData.message);
          }

          this.loading_master = false;
        })
        .catch((e) => {
          this.loading_master = false;
          this.axiosErrorHandler(e);
        });
    },
  },

  mounted() {
    this.getMaster();
  }
};
</script>